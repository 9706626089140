:root {
  --header-color: #ffffffa0;
  --header-line: #ccccd0;
  --back-color: #f2f2f7;
  --bg-col0: #ffffff;
  --bg-col1: #ffdfdf;
  --bg-col2: #ffefdf;
  --bg-col3: #ffffdf;
  --bg-col4: #efffdf;
  --bg-col5: #dfffdf;
  --bg-col6: #dfffef;
  --bg-col7: #dfffff;
  --bg-col8: #dfefff;
  --bg-col9: #dfdfff;
  --bg-col10: #efdfff;
  --bg-col11: #ffdfff;
  --bg-col12: #ffdfef;
  --bd-col0: #ffffff;
  --bd-col1: #ffbfbf;
  --bd-col2: #ffdfbf;
  --bd-col3: #ffffbf;
  --bd-col4: #dfffbf;
  --bd-col5: #bfffbf;
  --bd-col6: #bfffdf;
  --bd-col7: #bfffff;
  --bd-col8: #bfdfff;
  --bd-col9: #bfbfff;
  --bd-col10: #dfbfff;
  --bd-col11: #ffbfff;
  --bd-col12: #ffbfdf;
  --split-line: #ccccd0;
  --unactive: #7f7f7f;
  --norm: #000000;
  --info: #3a81f5;
  --warn: #ea3323;
}


@media (prefers-color-scheme: dark) {
  :root {

    --header-color: #202020a0;
    --header-line: #1c1c1e;
    --back-color: #000000;
    --bg-col0: #1c1c1e;
    --bg-col1: #5c1c1c;
    --bg-col2: #5c3c1c;
    --bg-col3: #5c5c1c;
    --bg-col4: #3c5c1c;
    --bg-col5: #1c5c1e;
    --bg-col6: #1c5c3e;
    --bg-col7: #1c5c5e;
    --bg-col8: #1c3c5e;
    --bg-col9: #1c1c5e;
    --bg-col10: #3c1c5e;
    --bg-col11: #5c1c5e;
    --bg-col12: #5c1c3e;
    --bd-col0: #1c1c1e;
    --bd-col1: #5c1c1e;
    --bd-col2: #5c3c1e;
    --bd-col3: #5c5c1e;
    --bd-col4: #3c5c1e;
    --bd-col5: #1c5c1e;
    --bd-col6: #1c5c3e;
    --bd-col7: #1c5c5e;
    --bd-col8: #1c3c5e;
    --bd-col9: #1c1c5e;
    --bd-col10: #3c1c5e;
    --bd-col11: #5c1c5e;
    --bd-col12: #3c1c5e;
    --split-line: #303034;
    --norm: #ffffff;
  }
}

body {
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--back-color);
  color: var(--norm);
}

#root {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.layout {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.layout-over {
  position: absolute;
  top: 0;
  animation: slidein 400ms;
}

.layout-over-slideout {
  position: absolute;
  top: 0;
  left: 100vw;
  animation: slideout 250ms;
  animation-timing-function: ease-out;
}

@keyframes slidein {
  from {
    left: 100vw;
  }
  to {
    left: 0;
  }
}

@keyframes slideout {
  from {
    left: 0;
  }
  to {
    left: 100vw;
  }
}

header {
  box-sizing: content-box;
  overflow: hidden;
  position: fixed;
  width: calc(100vw - 30px - env(safe-area-inset-left) - env(safe-area-inset-right));
  height: 40px;
  padding: 0;
  padding-left: calc(15px + env(safe-area-inset-left));
  padding-right: calc(15px + env(safe-area-inset-right));
  padding-top: env(safe-area-inset-top);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--back-color);
}

.top-scroller {
  box-sizing: content-box;
  overflow: hidden;
  position: fixed;
  width: 100vw;
  height: 8px;
  padding: 0;
  padding-top: env(safe-area-inset-top);
  background-color: #0000;
}

.header-untop {
  background-color: var(--header-color);
  backdrop-filter: blur(12px);
  border-bottom: solid 1px var(--header-line);
}

.header-title {
  font-weight: bold;
  text-align: center;
  margin: 0;
  overflow: hidden;
  width: auto;
  font-size: large;
  white-space: nowrap;
  text-overflow: ellipsis;
}

main {
  width: calc(100vw - 30px - env(safe-area-inset-left) - env(safe-area-inset-right));
  height: 1px;
  padding-top: calc(env(safe-area-inset-top) + 40px);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: calc(15px + env(safe-area-inset-left));
  padding-right: calc(15px + env(safe-area-inset-right));
  flex-grow: 1;
  overflow-y: auto;
  background-color: var(--back-color);
}

a {
  -webkit-touch-callout: none;
}

.listbox {
  display: block;
  overflow: hidden;
  margin: 15px 0;
  padding: 10px 15px;
  border-radius: 10px;
  color: var(--norm);
  text-decoration: none;
  background-color: var(--bg-col0);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header-left, .header-right {
  width: 60px;
  text-align: center;
  white-space: nowrap;
  color: var(--info);
  text-decoration: underline;
  font-weight: normal;
  font-size: large;
}

.content-box {
  display: block;
  margin: 15px 0;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: var(--bg-col0);
}

.memo-list {
  color: var(--norm);
  text-decoration: none;
}

hr {
  border: none;
  border-top: solid 1px var(--split-line);
  margin: 5px 0 15px 0;
}

.memo-split {
  margin: 15px 0;
}

.line-input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 0;
  outline: none;
  font-size: medium;
}

select {
  display: block;
  width: 100%;
  padding: 10px;
  background: none;
  font-size: medium;
  border: none;
  border-radius: 0;
}

textarea {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 0;
  outline: none;
  font-size: large;
  background: none;
  color: var(--norm);
}

input[type=text] {
  background: none;
  color: var(--norm);
}

.unactivelink {
  color: var(--unactive);
}

.alertbox {
  color: var(--warn);
  text-align: center;
}

.category-color {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: 5px;
  border-radius: 25px;
  border: solid 2px var(--split-line);
}

input[type=radio]:checked + .category-color {
  border: solid 2px var(--info);
}

.info-color {
  color: var(--info);
}

.memo-content-text {
  word-wrap: normal;
}

.bg-col0  {background-color: var(--bg-col0)}
.bg-col1  {background-color: var(--bg-col1)}
.bg-col2  {background-color: var(--bg-col2)}
.bg-col3  {background-color: var(--bg-col3)}
.bg-col4  {background-color: var(--bg-col4)}
.bg-col5  {background-color: var(--bg-col5)}
.bg-col6  {background-color: var(--bg-col6)}
.bg-col7  {background-color: var(--bg-col7)}
.bg-col8  {background-color: var(--bg-col8)}
.bg-col9  {background-color: var(--bg-col9)}
.bg-col10 {background-color: var(--bg-col10)}
.bg-col11 {background-color: var(--bg-col11)}
.bg-col12 {background-color: var(--bg-col12)}

.bd-col0  {border: solid 2px var(--bd-col0)}
.bd-col1  {border: solid 2px var(--bd-col1)}
.bd-col2  {border: solid 2px var(--bd-col2)}
.bd-col3  {border: solid 2px var(--bd-col3)}
.bd-col4  {border: solid 2px var(--bd-col4)}
.bd-col5  {border: solid 2px var(--bd-col5)}
.bd-col6  {border: solid 2px var(--bd-col6)}
.bd-col7  {border: solid 2px var(--bd-col7)}
.bd-col8  {border: solid 2px var(--bd-col8)}
.bd-col9  {border: solid 2px var(--bd-col9)}
.bd-col10 {border: solid 2px var(--bd-col10)}
.bd-col11 {border: solid 2px var(--bd-col11)}
.bd-col12 {border: solid 2px var(--bd-col12)}